import React, { useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { NavLink, useParams, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

// Map category path to API section names
const categoryRouteDataMap = [
    { title: "Section 1 (Writing)", matchPath: undefined, path: 'opening-text', link: "", section: 'opening_text' },
    { title: "Section 1 (Writing)", matchPath: 'opening-text', path: 'opening-text', link: "", section: 'opening_text' },
    { title: "Sites in Use (Writing)", matchPath: 'sites-in-use', path: 'sites-in-use', link: "sites-in-use", section: 'site_reviews' },
    { title: "WWW (Weblinks)", matchPath: 'www', path: 'www', link: "www", section: 'goings_online' },
    { title: "Oracle", matchPath: 'oracle', path: 'oracle', link: "oracle", section: 'oracle' },
];

const WritingPage = ({ actions, isMobile }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    // Extract only the category from the URL
    const { category } = useParams();

    // Determine effective section based on category
    const categoryMatch = categoryRouteDataMap.find(routeData => routeData.matchPath === category);
    const effectiveSection = categoryMatch?.section || 'opening_text';

    const articles = useSelector((state) => state?.writing.articles);
    const hasWritingSection = useSelector((state) => state?.writing.sectionsLoaded.includes(effectiveSection));

    // Track last selected article
    const [visibleArticleId, setVisibleArticleId] = useState(null);
    // Tracks initial scroll
    const [hasScrolled, setHasScrolled] = useState(false);

    // Filter articles by the effective section
    const categoryArticles = useMemo(() => {
        return articles?.filter(article => article.section === effectiveSection) || [];
    }, [articles, effectiveSection]);

    // Fallback article if no hash is present
    const fallbackArticle = categoryArticles.length > 0 ? categoryArticles[0] : null;

    // Get currentArticleId from the hash (e.g., #123)
    const currentArticleId = useMemo(() => {
        return location.hash ? location.hash.slice(1) : null;
    }, [location.hash]);

    // Determine the current article based on the hash or fallback
    // const currentArticle = useMemo(() => {
    //     if (!articles || _.isEmpty(articles)) return null;
    //     if (currentArticleId) {
    //         return articles.find(article => String(article.id) === String(currentArticleId)) || null;
    //     }
    //     return fallbackArticle;
    // }, [articles, currentArticleId, fallbackArticle]);

    // Refs for each article block
    const articleRefs = useRef({});
    const activeLinkRef = useRef(null);
    // this needs to be a ref because a state value will be cached
    // in the IntersectionObserver callback body
    const scrollState = useRef({
        scrolling: false,
        currentScrollEvtCallback: null
    });

    // Scroll to the article on first load or hash change
    useLayoutEffect(() => {
        if (!hasWritingSection || !currentArticleId || hasScrolled) return;

        setTimeout(() => {
            scrollToArticle(currentArticleId);
            setTimeout(() => {
                scrollToArticle(currentArticleId);
                setTimeout(() => {
                    scrollToArticle(currentArticleId);
                }, 150);
            }, 100);
        }, 50);
    }, [currentArticleId, categoryArticles, hasWritingSection, hasScrolled]);

    // Fetch articles if we haven't already
    useEffect(() => {
        if(!hasWritingSection) {
            dispatch(actions.fetchWriting({
                section: effectiveSection
            }));
        }
    }, [effectiveSection]);

    useEffect(() => {
        if( !centerNavRef.current){ return; }
        centerNavRef.current.scrollTo(0, 0);
    }, [categoryArticles]);

    const viewportBoundaryObserverRef = useRef(null);

    useEffect(() => {

        let scrollIntoViewRequested = false;

        const onScroll = () => {

            if(!scrollIntoViewRequested && (!scrollState.current.scrolling || !hasScrolled)) {
                scrollIntoViewRequested = true;
                requestAnimationFrame(() => {
                    activeLinkRef.current?.scrollIntoView({
                        block: "center",
                        behavior: "smooth"
                    });
                   scrollIntoViewRequested = false;
                })
            }
        }

        document.addEventListener('scroll', onScroll);

        viewportBoundaryObserverRef.current = new IntersectionObserver((entries, o) => {
            entries.forEach(entry => {

                if(!scrollState.current.scrolling && entry.isIntersecting) {
                    setVisibleArticleId(entry.target.dataset.articleId);
                }

            })
        }, {
            root: document,
            // set margin to a single line 20% below the top of viewport
            rootMargin: '-20% 0px -80% 0px',
            threshold: [0, 1]
        });

        return () => {
            viewportBoundaryObserverRef.current.disconnect();
            document.removeEventListener('scroll', onScroll);
        }

    }, [])

    const scrollToArticle = (id) => {

        const yOffset = !isMobile ? -40 : -133;
        const element = document.getElementById(id);

        if(!element) {
            return;
        }

        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

        // kill previous listener
        if(scrollState.current.currentScrollEvtCallback) {
            scrollState.current.currentScrollEvtCallback.cancel();
            document.removeEventListener('scroll', scrollState.current.currentScrollEvtCallback);
        }

        // scroll ends when no scroll happened in the last 100ms
        const onScrollEnd = _.debounce(() => {
            scrollState.current.scrolling = false;
            document.removeEventListener('scroll', onScrollEnd);
            scrollState.current.currentScrollEvtCallback = null;
        }, 100);

        document.addEventListener('scroll', onScrollEnd);

        scrollState.current.scrolling = true;
        scrollState.current.currentScrollEvtCallback = onScrollEnd;

        window.scrollTo({ top: y, behavior: 'smooth' });

        setVisibleArticleId(id);

        setHasScrolled(true);

    };

    // Prevent scrolling of parent container when over scrolling within the navigation.
    const centerNavRef = useRef(null);

    useEffect(() => {
        const nav = centerNavRef.current;

        const handleWheel = (e) => {
            const { scrollTop, scrollHeight, clientHeight } = nav;
            const atTop = scrollTop === 0;
            const atBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
            const scrollingUp = e.deltaY < 0;
            const scrollingDown = e.deltaY > 0;

            // If at the top of nav and trying to scroll up, or
            // at the bottom of nav and trying to scroll down,
            // prevent scrolling from propagating to the parent container.
            if ((atTop && scrollingUp) || (atBottom && scrollingDown)) {
                e.preventDefault();
                e.stopPropagation();
            }
        };

        nav.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            nav.removeEventListener('wheel', handleWheel);
        };
    }, [category]);

    // Convert tarot image name to image object from S3 bucket.
    const processTarotImage = (tarotImageName) => {

        if (!tarotImageName || tarotImageName.length === 0) return null;
        const isReversed = tarotImageName.includes('-reversed');
        const cleanName = tarotImageName.replace('-reversed', '');
        
        // Capitalize the first letter of each word, unless it's "of"
        const capitalizeWord = (word) => word === 'of' ? word : word.charAt(0).toUpperCase() + word.slice(1);

        let readableName = cleanName
            .replace(/-/g, ' ')
            .split(' ')
            .map(capitalizeWord)
            .join(' ') + (isReversed ? ' (reversed)' : '');

        const baseUrl = 'https://static.cargo.site/assets/tarot/cards/';
        const src = isReversed
            ? `${baseUrl}reversed/${cleanName}.gif`
            : `${baseUrl}regular/${cleanName}.gif`;

        return { src, alt: readableName, caption: readableName };
    };

    // Convert uploaded I Ching image object to JSX.
    const processIChingImage = (iChingImage) => {
        if (!iChingImage) return null;

        const src = `https://freight.cargo.site/m/${iChingImage.hash}/${iChingImage.name}`;
        const caption = iChingImage.caption;

        return { src, alt: iChingImage.name, caption };
    };

    const OracleImage = ({ image, type }) => (
        <div className={`oracle-image ${type}`}>
            <img src={image.src} alt={image.alt} />
            <div
                className="oracle-image-caption"
                dangerouslySetInnerHTML={{ __html: image.caption }}
            />
        </div>
    );

    const LinkButton = ({ label, route, checked, active, article }) => (

        <NavLink
            exact
            to={route}
            className={`button-link${(active && article) || (checked && !article) ? ' active' : ''}`}
            onDragStart={(e) => { e.preventDefault(); }}
            onContextMenu={(e) => { e.preventDefault(); }}
        >
            {checked ? (<>{'\uE001'}{'\u00A0'}</>) : null}{label}
        </NavLink>
    );

    const activeCategoryPath = useMemo(() => {
        const thisCategoryMatch = categoryRouteDataMap.find(crdm => crdm.section === effectiveSection);
        return thisCategoryMatch ? thisCategoryMatch.path : 'opening-text';
    }, [effectiveSection, categoryRouteDataMap]);

    return (
        <div writing-page={activeCategoryPath}>
            <div className="category-navigation">

                {_.map(categoryRouteDataMap, (categoryData, index) => {
                    const { title, matchPath, link, path } = categoryData;
                    if( !matchPath ){ return null }
                    const linkRoute = `/writing/${link}`;
                    return (
                        <div key={index}>
                            <LinkButton
                                label={title}
                                route={linkRoute}
                                checked={activeCategoryPath === path}
                            />
                        </div>
                    );
                })}
            </div>
            <div className="section-title">
                <span>{categoryMatch ? categoryMatch?.title : categoryRouteDataMap[0].title}</span>
            </div>
            <div className="page-scroll">
                <div className="article-navigation" ref={centerNavRef}>
                    {hasWritingSection && categoryArticles.map((article, index) => {
                        const articleCategoryMatch = categoryRouteDataMap.find(routeData => routeData.section === article.section);
                        
                        const articleRoute = article.section === 'opening_text' ? `/writing/#${article.id}` : `/writing/${articleCategoryMatch.path}/#${article.id}`;

                        const articleTitle = category === 'sites-in-use' 
                        ? String(categoryArticles.length - index).padStart(5, '0') 
                        : article.title;

                        return (
                            <div key={article.id}>
                                <a
                                    ref={article.id == visibleArticleId ? activeLinkRef : null}
                                    href={articleRoute}
                                    className={`button-link${article.id == visibleArticleId ? ' active' : ''}`}
                                    onDragStart={(e) => { e.preventDefault(); }}
                                    onContextMenu={(e) => { e.preventDefault(); }}
                                    onClick={(e) => {
                                        // console.log("currentArticle", currentArticle)
                                        e.preventDefault();
                                        window.history.replaceState(null, null, articleRoute);
                                        scrollToArticle(article.id);
                                    }}
                                >{articleTitle}</a>
                            </div>
                        );
                    })}
                </div>
                <div className="page-window">
                    {categoryArticles.map((article) => {
                        const { id, section, title, tarot_image, media, content } = article;

                        const dinkusPatterns = [
                            /\*\*\*/g,
                            /\* \* \*/g,
                            /\* &nbsp;\* &nbsp;\*/g,
                            /\* &nbsp; \* &nbsp; \*/g,
                            /❉ ❉ ❉/g,
                            /❉ &nbsp;❉ &nbsp;❉/g,
                            /﹡﹡﹡/g
                        ];
                        
                        function replaceDinkus(content) {
                            dinkusPatterns.forEach(pattern => {
                                content = content
                                    .replace(new RegExp(`${pattern.source}\\s*<br>`, 'g'), '<hr />')
                                    .replace(new RegExp(`<div[^>]*>\\s*${pattern.source}\\s*<\\/div>`, 'g'), '<hr />')
                                    .replace(new RegExp(`</div>${pattern.source}`, 'g'), '</div><br /><hr />')
                                    .replace(pattern, '<hr />')

                            });
                            return content;
                        }

                        // format article
                        const formattedContent = replaceDinkus(content);

                        // Process images
                        const tarotImage = processTarotImage(tarot_image);
                        const iChingImage = processIChingImage(media?.[0]);
                        // Regex to validate URL
                        const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/.*)?$/;

                        return (
                            <div
                                key={id}
                                data-article-id={id}
                                id={`${id}`} // Use article id directly for hash linking
                                className="article-block"
                                ref={(el) => {
                                    if(el) {
                                        viewportBoundaryObserverRef.current?.observe(el);
                                    }
                                    articleRefs.current[id] = el;
                                }}
                                data-article-section={section} // Changed attribute to data-attribute for validity
                            >
                                <div className="article-content">
                                    <div className="article-title">
                                        {section === 'site_reviews' && urlRegex.test(title) ? (
                                            <a href={`https://${title}`} target="_blank" rel="noopener noreferrer">
                                                {title}
                                            </a>
                                        ) : (
                                            title
                                        )}
                                    </div>

                                    {section === 'oracle' && (tarotImage || iChingImage) ? (
                                        <>
                                            <div className="oracle-images">
                                                {tarotImage && <OracleImage type="tarot" image={tarotImage} />}
                                                {iChingImage && <OracleImage type="iching" image={iChingImage} />}
                                            </div>
                                            <br />
                                        </>
                                    ) : ( 
                                        section !== 'goings_online' && <br />
                                    )}

                                    <div
                                        className="article-body"
                                        dangerouslySetInnerHTML={{ __html: formattedContent }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

function mapReduxStateToProps(state, ownProps) {
    return {
		isMobile: state.homepageState.isMobile,
    };
}

export default connect(
	mapReduxStateToProps
)(WritingPage);