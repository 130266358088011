import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import LoginMenu from './login-menu';
import { AnimatedElement, fadeInGlideUp1, fadeInGlideUp3, useAnimationPath } from "@cargo/common/animation-context";


const HomeMenu = ({ isMobile, layeredRouter, routeInfo, isLoggedIn }) => {

    // check what the top layer has rendered
    const { isTemplates, isCommunity, isHomePage, isFonts, isStaticPage } = _.last(layeredRouter.layerStack).routeInfo;

	// For triggering the text reveal fade animation on page load
    const initialLocation = layeredRouter.getInitialRouteOnLoad();
    const shouldApplyInitialStyles = useAnimationPath('fade', '/', true, initialLocation.pathname);
    // const overlayRoutePresent = layeredRouter.layerStack.length > 1;
    
    return (
        <div id="home-menu">
            {!isMobile ? (
                <div className="left-area">
                    <AnimatedElement
                        as="span"
                        animation={fadeInGlideUp3}
                        animationKey="fade"
                        applyInitialStyles={shouldApplyInitialStyles}
                    >
                    {isHomePage ? (
                        <span className="button-link intro">
                            <span>Cargo</span>
                        </span>
                    ) : (
                        <NavLink exact
                            className={`button-link`}
                            to='/'>
                            <span>Cargo</span>
                        </NavLink>
                    )}
                    </AnimatedElement>
                </div>
            ) : null}



            <div className="right-area">
                     <AnimatedElement
                        as="span"
                        animation={fadeInGlideUp3}
                        animationKey="fade"
                        applyInitialStyles={shouldApplyInitialStyles}
                    > 
                        <NavLink exact
                            className={`button-link templates`}
                            isActive={() => isTemplates}
                            activeClassName="selected"
                            to='/templates'
                        >
                            <span>Templates</span>   
                        </NavLink>

                        <NavLink exact
                            className={`button-link community`}
                            isActive={() => isCommunity}
                            activeClassName="selected"
                            to='/community/all'
                        >
                            <span>Community</span>
                        </NavLink>

                        {!isMobile ? (
                            <NavLink exact
                                className={`button-link`}
                                isActive={() => isFonts}
                                activeClassName="selected"
                                to='/fonts'
                            >
                                <span>Fonts</span>   
                            </NavLink>
                        ) : null}

                        <NavLink exact
                            className={`button-link`}
                            isActive={() => isStaticPage}
                            activeClassName="selected"
                            to='/information'
                        >
                            <span>Information</span>
                        </NavLink>
                    </AnimatedElement> 
                {!isLoggedIn && <LoginMenu />}
            </div>
        </div>
    );
}

function mapReduxStateToProps(state, ownProps) {

    return {
        isMobile: state.homepageState.isMobile
    };

}

export default withRouter(connect(
    mapReduxStateToProps
)(HomeMenu));