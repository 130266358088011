import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AnimatedElement, fadeInGlideUp1, fadeInGlideUp2, fadeInGlideUp3, fadeInGlideUp4, useAnimationPath } from "@cargo/common/animation-context";
import { TextReveal } from "@cargo/ui-kit/text-reveal/text-reveal";
import DownwardsArrow from "@cargo/common/icons/downwards-arrow.svg";
import Clock from "./clock";
import { SiteDuplicationContext } from './site-duplication-provider';

const HomePage = ({isMobile, layeredRouter}) => {

	// For triggering the text reveal fade animation on page load
    const initialLocation = layeredRouter.getInitialRouteOnLoad();
	const shouldApplyInitialStyles = useAnimationPath('fade-paragraph', '/', true, initialLocation.pathname);
	const { loginFromContext } = useContext(SiteDuplicationContext);

	const [opacity, setOpacity] = useState(1);

	useEffect(() => {
		const communityLink = document.querySelector('.top-menu-bar a.button-link.community');

		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			const scrollThreshold1 = window.innerHeight * 0.5;
			const scrollThreshold2 = window.innerHeight * 0.75;

			// Fade out arrow & info blurb, scrolling 50% of the viewport height
			let toZeroOpacity = 1;
            if (scrollPosition <= scrollThreshold1) {
                toZeroOpacity = 1 - (scrollPosition / scrollThreshold1);
            } else {
                toZeroOpacity = 0;
            }			
			setOpacity(toZeroOpacity);

			// Fake selected color on community link, scrolling 50% to 75% of the viewport height
			let toSelectedOpacity = 1;
            if (scrollPosition >= scrollThreshold1 && scrollPosition <= scrollThreshold2) {
                const normalizedScroll = (scrollPosition - scrollThreshold1) / (scrollThreshold2 - scrollThreshold1);
                toSelectedOpacity = 1 - (normalizedScroll * (1 - 0.53));
            } else if (scrollPosition > scrollThreshold2) {
                toSelectedOpacity = 0.53;
            }
			if (communityLink) {
				communityLink.style.opacity = toSelectedOpacity;
			}
		};
	
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
			if (communityLink) {
                communityLink.style.opacity = '';
            }
		};
	}, [isMobile]);

    return (
        <>
            {isMobile ? (
				<div className="mobile-arrow" style={{ opacity: opacity }}>
					<AnimatedElement
						as="span"
						animation={fadeInGlideUp3}
						animationKey="fade-paragraph"
						applyInitialStyles={shouldApplyInitialStyles}
					>
						<DownwardsArrow />
					</AnimatedElement>
				</div>
			) : null}

			<h1>
				<TextReveal
					texts={["Cargo"]}
					appearAsText={!shouldApplyInitialStyles}
					customClass={"cargo"}
					letterClasses={['c','','r','g','']}
				/>
				<br />
				<TextReveal 
					texts={["Site Builder"]}
					initialDelay={2350}
					appearAsText={!shouldApplyInitialStyles}
					customClass="site-builder"
					endIcon={"®"}
					endIconDelay={500}
				/>

			</h1>

			{!isMobile ? (
				<>
					<h2>
						<AnimatedElement
							as="span"
							animation={fadeInGlideUp3}
							animationKey="fade-paragraph"
							applyInitialStyles={shouldApplyInitialStyles}
						>
							<NavLink exact
								className={`templates`}
								to='/templates'
							>
								Explore Sites&nbsp;<span className="arrow">→</span>
								</NavLink>
							<br />
							<span className="start text-button" onClick={()=>{ loginFromContext({canCreateNewAccount: true}) }}>
								S<span className="t">t</span><span className="a">a</span><span className="r">r</span>t&nbsp;<span className="arrow">→</span>
							</span>
						</AnimatedElement>
					</h2>
					<div className="fixed-clock">
						<AnimatedElement
							as="span"
							animation={fadeInGlideUp3}
							animationKey="fade-paragraph"
							applyInitialStyles={shouldApplyInitialStyles}
						>
							<Clock />
						</AnimatedElement>
					</div>
				</>
			) : null}
			
			<div className="fixed-information" style={{ opacity, pointerEvents: opacity === 0 ? 'none' : 'auto' }}>
				<AnimatedElement
					as="span"
					animation={fadeInGlideUp3}
					animationKey="fade-paragraph"
					applyInitialStyles={shouldApplyInitialStyles}
				>
					<NavLink exact
							className={`fonts-link`}
							to='/fonts'
					>
						<div className="dinamo-image"
							style={{
								// visibility: this.state.firstLayoutLoaded ? '': 'hidden',
								backgroundImage: `url(${PUBLIC_URL}/images/dinamo-green.jpg)`
							}}			
						/>
					</NavLink>
				</AnimatedElement>
			</div>

			<div className="fixed-information text" style={{ opacity, pointerEvents: opacity === 0 ? 'none' : 'auto' }}>
				<AnimatedElement
					as="span"
					animation={fadeInGlideUp3}
					animationKey="fade-paragraph"
					applyInitialStyles={shouldApplyInitialStyles}
				>
					Cargo is a site builder for designers and artists. We are interested in two main things: helping creative people secure jobs/clients through showing work and displaying work, generally. As well, we host a network of peers to show great sites and work. This is Cargo’s passion and purpose.&nbsp;
					<NavLink exact
						className={`button-link more-information`}
						to='/information'
					>[more...]</NavLink>
				</AnimatedElement>
			</div>

        </>
    );
};

function mapReduxStateToProps(state, ownProps) {
    return {
		isMobile: state.homepageState.isMobile
    };
}

export default withRouter(connect(
	mapReduxStateToProps
)(HomePage))