import { combineReducers } from 'redux';

/* import reducers */
import auth from "./auth";
import account from "./account";
import folders from "./folders";
import templates from "./templates";
import sites from "./sites";
import homepageState from "./homepage-state";
import uiWindows from "./ui-windows";
import collaborators from "./collaborators";
import deletedSites from "./deleted-sites";
import subscriptions from "./subscriptions";
import sitePreview from "./site-preview";
import community from "./community";
import publicFolders from "./public-folders";
import paginatedJobs from './jobs';
import userJobs from './user-jobs';
import jobsFilter from './jobs-filter';
import filteredJobs from './filtered-jobs';
import writing from './writing';

export default combineReducers({
	auth,
	account,
	folders,
	templates,
	sites,
	collaborators,
	homepageState,
	uiWindows,
	deletedSites,
	subscriptions,
	sitePreview,
	community,
	publicFolders,
	paginatedJobs,
	userJobs,
	jobsFilter,
	filteredJobs,
	writing
});