import { actionTypes } from "../actions";

import _ from "lodash";

const defaultWritingState = {
    sectionsLoaded: [],
    articles: []
}

export default function (state = defaultWritingState, action) {

    if ( !action.payload ) {
        return state;
    }

    switch (action.type) {

        case actionTypes.FETCH_WRITING_PENDING:

            return {
                ...state
            }

            break;

        case actionTypes.FETCH_WRITING_REJECTED:

            return {
                ...state
            }

            break;

        case actionTypes.FETCH_WRITING_FULFILLED:

            const newArticles = action.payload.data;

            let newSectionsLoaded = [...state.sectionsLoaded];

            if(action.meta.section && !state.sectionsLoaded.includes(action.meta.section)) {
                newSectionsLoaded.push(action.meta.section)
            } else {
                // we loaded everything
                newSectionsLoaded = _.uniq(newArticles.map(a => a.section))
            }

            return {
                ...state,
                sectionsLoaded: newSectionsLoaded,
                articles: [
                    ...state.articles,
                    ...newArticles
                ]
            }

            break;

    }

    return state;

};